<template>
<div class ="mainButton__container">
<div class = "mainButton">
  <router-link :to="link" class="hyper-link">
    <span class ="mainButton_title">{{ title }}</span>
  </router-link>
</div>
</div>
</template>

<script>
export default {
name:"MainButton",
  props: {
    title: String,
    link: String,
  },
};
</script>

<style lang="scss" scoped>
.mainButton__container{
  width:180px;
}
.hyper-link{
	text-decoration: none;
    color:white;
}
.mainButton{
    text-align:center;
    font-weight:700;
    font-size:15px;
    color:white !important;
    text-decoration: none !important;
    padding:15px;
    margin:auto;
    background-color:#F6CD6C;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 10px;
}
@media only screen and (min-width: 1024px) {
  .mainButton{
    font-size:13px;
  }
}
</style>
