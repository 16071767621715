import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/facebook",
    beforeEnter() {
      // Put the full page url including the protocol http(s) below
      window.location = "https://facebook.com/UniversalCommunityServices/"
  }
  }
];

const router = new VueRouter({
  mode: "history",
  scrollBehavior: function(to) {
    if (to.hash) {
      return window.scrollTo({
        top: document.querySelector(to.hash).offsetTop,
        behavior: "smooth",
      });
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes,
});

export default router;
