<template>
  <section class="project__section" id="Projects">
    <div
      class="project__title__warpper"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/images/logo/handshake.png') + ')',
      }"
    >
      <p class="project__title center">Projects</p>
    </div>
    <div class="project__description__warpper" :style="{
        backgroundImage:
          'url(' + require('@/assets/images/logo/logo.png') + ')',}">
      <div class="project__descrption">Coming Soon To Your Community</div>
    </div>
  </section>
</template>
<script>
export default {
  name: "ProjectSection",
};
</script>
<style lang="scss">
.project__section {
  color: black;
  padding-top: 50px;
  font-weight: 700;
  background-color: #f4f9fc;
  padding-bottom: 50px;
  .project__title__warpper {
    /* height:80px; */
    text-align: center;
    background-repeat: no-repeat;
    background-position: center;
    background: cover;
    background-size: 100px 100px;
  }
  .project__title {
    font-size: 35px;
    padding: 40px;
    margin: 0;
  }
  .project__description__warpper {
    height: 300px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position:center;
    background-repeat: no-repeat;
    background-size: 300px 300px;
    opacity: 0.8;
    
  }
  .project__descrption {
      opacity: 1;
      font-size:30px;
      margin:10%
  }
}
</style>
