<template>
    <section class="vision__section" id="Vision">
        <div class ="vision__warpper curved" :style="{ backgroundImage: 'url(' + require('@/assets/images/visionsection.jpg') + ')' }">
        <svg class ="upsidedown" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 130"><path fill="#fff" fill-opacity="1" d="M0,128L60,128C120,128,240,128,360,122.7C480,117,600,107,720,90.7C840,75,960,53,1080,48C1200,43,1320,53,1380,58.7L1440,64L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path></svg>
        <div class = "vision_title">Our Vision</div>
        <div class = "vision_description"> Our vision is to involve youth in the community, helping them to become responsible and active citizens. We will be offering a variety of programs and services that are designed to help young people develop the skills and confidence they need to succeed in life.</div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 130"><path fill="#fff" fill-opacity="1" d="M0,128L60,128C120,128,240,128,360,122.7C480,117,600,107,720,90.7C840,75,960,53,1080,48C1200,43,1320,53,1380,58.7L1440,64L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path></svg>
        </div>
    </section>
</template>
<script>

export default {
    name:"VisionSection"
}
</script>
<style lang = "scss" scoped>
.vision__warpper{
    /* min-height:400px; */
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    color:white;
    font-weight: 700;

    .vision_title {
        font-size: 35px;
        padding-bottom: 30px;
        padding-top:80px;
    }
    .vision_description{
        padding-bottom: 80px;
        max-width:450px;
        margin:0 auto;
        padding-left:30px;
        padding-right:30px;
    }
    
    
}
.upsidedown{
    transform: rotate(180deg);
}
.curved svg{
    display:block
}
</style>