<template>
  <div id="app">
    <nav-bar />
    <router-view />
  </div>
</template>
<script>
import NavBar from "./components/NavBar.vue";
export default {
  components: {
    NavBar,
  },
};
</script>
<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
}
body {
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif !important;
}

#sectionTitles {
  font-family: "Raleway", sans-serif;
  font-weight: 100;
  font-size: 14px;
}
</style>
