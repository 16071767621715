<template>
    <section class ="footer">
        <div class="content">
            Copyright 2020 - <b>Universal Community Service Limited</b>
            <br/>
            <br/>
            <b class="webbricks">Another Website By</b>
            <br/>
            <img
                class="nav__logo"
                :src="require(`@/assets/images/logo/webBricks.png`)"
                alt="webBricks-logo"
                width="150px"
                height="31px"
              />
            <br/>
        </div>
    </section>
</template>
<script>

export default {
    name:"Footer"
}
</script>
<style lang ="scss" scoped>
.footer{
    background-color:#182730
}
.content{
    color:#FFFFFF;
    padding:50px;
}
.webbricks{
    font-size:13px;
}
</style>
