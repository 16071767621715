<template>
  <div style="height:1080px; background-color:grey;">
    <hero-section/>
    <vision-section/>
    <about-section/>
    <!-- <filler-section/> -->
    <project-section/>
    <!-- <team-section/> -->
    <contact-section/>
    <Footer/>
  </div>
</template>
<script>
import HeroSection from "@/components/home/HeroSection.vue"
import VisionSection from "@/components/home/VisionSection.vue"
import AboutSection from "@/components/home/AboutSection.vue"
// import FillerSection from "@/components/home/FillerSection.vue"
import ProjectSection from "@/components/home/ProjectSection.vue"
// import TeamSection from "@/components/home/TeamSection.vue"
import ContactSection from "@/components/home/ContactSection.vue"
import Footer from "@/components/Footer.vue"

export default {
  components: {
    HeroSection,
    VisionSection,
    AboutSection,
    // FillerSection,
    ProjectSection,
    // TeamSection,
    ContactSection,
    Footer
  },
}
</script>

