<template>
<section class ="about__section" id="AboutUs">
    <div class="about__title__warpper" :style="{ backgroundImage: 'url(' + require('@/assets/images/logo/handshake.png') + ')' }">
    <p class ="about__title center"> About Us</p>
    </div>
    <div class ="about__descrption"> UCS is a non-profit organization that is dedicated to helping youth in our community. We believe that every child deserves the opportunity to reach their full potential, and we strive to empower young people to make better choices and build strong character.</div>
</section>
</template>
<script>
export default {
    name:"AboutSection",

}
</script>
<style lang ="scss" >
.about__section{
    color:black;
    font-weight:700;
    .about__title__warpper{
        /* height:80px; */
        margin-top:3em;
        text-align: center;
        background-repeat: no-repeat;
        background-position: center;
        background:cover;
        background-size: 100px 100px;
    }
    .about__title{
        font-size: 35px;
        padding:40px;
        margin:0
    }
    .about__descrption{
        padding-bottom: 80px;
        max-width:720px;
        margin:0 auto;
        padding-left:30px;
        padding-right:30px;
    }
}

</style>