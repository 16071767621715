<template>
  <div>
    <nav>
      <div id="nav-id" class="nav">
        <div class="nav-web nav__wrapper" v-if="webView">
          <div class="space-balance">
            <router-link to="/" exact>
              <img
                class="nav__logo"
                :src="require(`@/assets/images/logo/logo.png`)"
                alt="webBricks-logo"
                width="50px"
                height="50px"
              />
            </router-link>
          </div>
          <div class="nav-web__links">
            <router-link to="/">Home</router-link>
            <!-- <router-link to="#AboutUs">About Us</router-link>
            <router-link to="#Services">Vision</router-link>
            <router-link to="#Projects">Projects</router-link> -->
            <router-link to="#ContactUs">Contact Us</router-link>
          </div>
          <div class="colored-btn-warpper">
            <a class="colored-btn" href="https://facebook.com/UniversalCommunityServices/"
              >Our Social Media</a
            >
          </div>
        </div>
        <div class="nav-tablet nav__wrapper" v-if="tabletView">
          <router-link to="/" exact>
            <img
              class="nav__logo"
              :src="require(`@/assets/images/logo/logo.png`)"
              alt="webBricks-logo"
              width="50px"
              height="50px"
          /></router-link>
          <div class ="center_logo"><img
              class="nav__logo__center"
              :src="require(`@/assets/images/logo/logo_words.png`)"
              alt="webBricks-logo"
              width="120px"
              height="50px"
          /></div>
          <div class="nav-tablet__toggle-btn-wrapper">
            <div
              id="nav-tablet-toggle-btn"
              class="toggle-btn"
              @click="toggleSideBar()"
            >
              <div class="btn-burger"></div>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="sidebar" id="sidebar-id">
      <ul>
        <router-link tag="li" to="/" exact>Home</router-link>
        <!-- <router-link tag="li" to="#Vision">Vision</router-link>
        <router-link tag="li" to="#AboutUs">About Us</router-link>
        <router-link tag="li" to="#Projects">Projects</router-link> -->
        <router-link tag="li" to="#ContactUs">Contact Us</router-link>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "navbar",
  components: {
    // "nav-bar-mobile": NavBarMobile
  },
  data: () => {
    return {
      windowWidth: window.innerWidth,
      prevScrollHeight: null,
      sidebarActive: false,
      scrollingDown: false,
      prevToggleBtn_isTransparent: false,
    };
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    toggleSideBar() {
      if (!this.mobileView) {
        document
          .getElementById("nav-tablet-toggle-btn")
          .classList.toggle("toggle-btn--active");
      } else {
        document
          .getElementById("mobile-toggle-btn")
          .classList.toggle("toggle-btn--active");
        if (this.scrollingDown) {
          this.displayToggleBtn();
          this.prevToggleBtn_isTransparent = true;
        }
        if (this.sidebarActive && this.prevToggleBtn_isTransparent) {
          this.hideToggleBtn();
          this.prevToggleBtn_isTransparent = false;
        }
      }
      document.getElementById("sidebar-id").classList.toggle("sidebar--active");
      this.sidebarActive = !this.sidebarActive;
    },
    handleScroll() {
      let scrollHeightNow = window.scrollY;
      if (scrollHeightNow > 0) {
        if (this.prevScrollHeight > window.scrollY) {
          this.setScrollUpNavStyle();
        } else {
          this.setScrollDownNavStyle();
        }
        this.prevScrollHeight = scrollHeightNow;
      }
    },
    setScrollUpNavStyle() {
      if (this.mobileView) {
        if (!this.sidebarActive) {
          this.displayToggleBtn();
        }
      }
      document.getElementById("nav-id").style.top = "0px";
    },
    setScrollDownNavStyle() {
      if (this.mobileView) {
        if (!this.sidebarActive) {
          this.hideToggleBtn();
        }
      }
      document.getElementById("nav-id").style.top = "-100px";
    },
    displayToggleBtn() {
      var toggle_btn = document.getElementById("mobile-toggle-btn-wrapper");
      if (toggle_btn.classList) {
        if (
          toggle_btn.classList.contains(
            "nav-mobile__toggle-btn-wrapper--translucent"
          )
        ) {
          toggle_btn.classList.toggle(
            "nav-mobile__toggle-btn-wrapper--translucent"
          );
          this.scrollingDown = false;
        }
      }
    },
    hideToggleBtn() {
      var toggle_btn = document.getElementById("mobile-toggle-btn-wrapper");
      if (toggle_btn.classList) {
        if (
          !toggle_btn.classList.contains(
            "nav-mobile__toggle-btn-wrapper--translucent"
          )
        ) {
          toggle_btn.classList.toggle(
            "nav-mobile__toggle-btn-wrapper--translucent"
          );
          this.scrollingDown = true;
        }
      }
    },
  },
  computed: {
    webView() {
      // console.log(this.windowWidth >= 1024)
      return this.windowWidth >= 1100;
    },
    tabletView() {
      return this.windowWidth < 1100;
    },
  },
  watch: {
    $route: function() {
      if (!this.webView) {
        var side_bar = document.getElementById("sidebar-id");
        if (side_bar.classList) {
          if (side_bar.classList.contains("sidebar--active")) {
            side_bar.classList.remove("sidebar--active");
          }
        }
        if (this.tabletView) {
          var tablet_toggle_btn = document.getElementById(
            "nav-tablet-toggle-btn"
          );
          if (tablet_toggle_btn.classList) {
            if (tablet_toggle_btn.classList.contains("toggle-btn--active")) {
              tablet_toggle_btn.classList.remove("toggle-btn--active");
            }
          }
        }
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.onResize);
  },
};
</script>
<style lang="scss" scoped>
.center_logo{
  padding:20px;
  margin: auto;
}
.space-balance {
  width: 20%;
}
.colored-btn-warpper {
  width: 20%;
}
.colored-btn {
  padding: 15px;
  padding-left: 40px;
  padding-right: 40px;
  text-decoration: none;
  font-weight: 700 !important;
  color: #182730;
  background-color: #f6cd6c;
  border-radius: 10px 30px;
}
.nav {
  position: fixed;
  width: 100%;
  -webkit-transition: 0.8s ease-in-out;
  -moz-transition: 0.8s ease-in-out;
  -ms-transition: 0.8s ease-in-out;
  -o-transition: 0.8s ease-in-out;
  transition: 0.8s ease-in-out;
  z-index: 4;
  background-color: #ffffff;
}
.nav__wrapper {
  top: 0;
  display: flex;
  align-items: center;
  //   justify-content: space-between;
  height: 80px;
  @media only screen and (max-width: 1023.99px) {
    height: 80px;
  }
}
.nav__logo {
  padding: 20px;
}
.nav-web__links {
  padding: 20px;
  margin: auto;
  a,
  button {
    color: #182730;
    font-weight: 700 !important;
    text-decoration: none;
    margin: 2em;
    // padding-bottom: 3px;
    font-weight: lighter;
    transition: 200ms all ease-out;
    &.router-link-active,
    &:hover {
      border-bottom: 2px solid #f6cd6c;
      color: #f6cd6c;
      text-shadow: 0 0 0.9px #f6cd6c, 0 0 0.9px #f6cd6c, 0 0 0.9px #f6cd6c;
    }
  }
}
.nav-web__contact-us-button {
  padding: 0.7em 3em;
  border-radius: 25px;
  border: none;
  margin: 0.2em !important;
  background-image: linear-gradient(to right, #3979a0, #26a0da);
  &:hover {
    padding: 0.9em 3.2em;
    margin: 0em !important;
    cursor: pointer;
  }
}
.nav-tablet__toggle-btn-wrapper {
  padding: 30px;
}
.nav-mobile__toggle-btn-wrapper {
  position: fixed;
  bottom: 25px;
  right: 30px;
  padding: 1.3em;
  cursor: pointer;
  border-radius: 50%;
  background-image: linear-gradient(#3979a0, #26a0da);
  opacity: 1;
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  -ms-transition: opacity 0.5s ease-in-out;
  -o-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
}
.nav-mobile__toggle-btn-wrapper--translucent {
  opacity: 0.2;
}
.sidebar {
  top: -100%;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  z-index: 3;
  cursor: pointer;
  transition: all 300ms linear;
  ul {
    list-style: none;
    padding: 10vh 0 0 0;
    li {
      padding: 15px 10px;
      width: 50%;
      margin: auto;
      color: white;
      text-decoration: none;
      &.router-link-exact-active {
        color: #ffffff !important;
        border-bottom: 1px solid white;
        padding-bottom: 0.5rem;
      }
    }
  }
}
.sidebar--active {
  top: 0px !important;
}
.toggle-btn {
  height: 25px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.toggle-btn--active {
  .btn-burger {
    transform: rotate(135deg);
  }
  .btn-burger::before,
  .btn-burger::after {
    top: 0;
    transform: rotate(90deg);
  }
}
.btn-burger {
  position: relative;
  max-width: 30px;
  max-height: 5px;
  width: 1.5em;
  height: 0.2em;
  background: #f6cd6c;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  transition: all 0.7s ease;
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: -10px;
    max-width: inherit;
    max-height: inherit;
    min-width: inherit;
    min-height: inherit;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    background: inherit;
  }
  &::after {
    top: 10px;
  }
}
</style>
