<template>
  <section id="ContactUs" class="contact__section">
    <div class="contact_content">
      <div class ="contact__elements">
      <div class="contact__details">
        <div class="contact_email">
          <font-awesome-icon
            class="contact__icon"
            :icon="['fas', 'mobile-alt']"
          /><br />Email to Us<br />
          <div class="contact_instrument">hi@universalcommunity.com</div>
        </div>
        <div class="contact_phone">
          <font-awesome-icon
            class="contact__icon"
            :icon="['fas', 'envelope-open-text']"
          /><br />Talk to Us<br />
          <div class="contact_instrument">+65 8908 6698</div>

          <div class="contact_instrument">14 Robinson Road, #08-01A Far East Finance Building, Singapore 048545</div>
        </div>
      </div>
      </div>
      <div class ="contact__elements">
      <div class="contact_maps">
        <iframe
          src="https://maps.google.com/maps?q=Far%20East%20Finance%20Building&t=&z=13&ie=UTF8&iwloc=&output=embed"
          frameborder="0"
          style="border: 0; width: 100%; height: 330px;"
          allowfullscreen
        ></iframe>
      </div>
      </div>

<div class ="contact__elements">
      <div class="contact_form">
        <div class="contact__input name__input">
          <input type="text" id="fname" name="fname" placeholder="Name" />
        </div>
        <div class="contact__input email__input">
          <input type="text" id="fname" name="fname" placeholder="Email" />
        </div>
        <div class="contact__input subject__input">
          <input type="text" id="fname" name="fname" placeholder="Subject" />
        </div>
        <div class="contact__input details__input">
          <textarea
            type="text"
            id="fname"
            name="fname"
            placeholder="Details"
          ></textarea>
        </div>
        <div class="mainButton__container">
          <div class="mainButton">
            <router-link to="/" class="hyper-link">
              <span class="mainButton_title"> Send </span>
            </router-link>
          </div>
        </div>
      </div>
</div>
    </div>
  </section>
</template>
<script>
export default {
  name: "ContactSection",
};
</script>
<style lang="scss" scoped>
.mainButton__container{
  margin:auto;
  width:180px;
}
.hyper-link{
	text-decoration: none;
    color:white;
}
.mainButton{
    text-align:center;
    font-weight:700;
    font-size:15px;
    color:white !important;
    text-decoration: none !important;
    padding:15px;
    margin:auto;
    background-color:#F6CD6C;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 10px;
}
@media only screen and (min-width: 1024px) {
  .mainButton{
    font-size:13px;
  }
  .contact_content{
    display:flex;
    justify-content: center;
  }
  .contact__section{
    // padding-left:30%
  }
}
@media only screen and (max-width: 1024px) {
  .contact__details,.contact_maps,.contact_form{
    margin:auto;
  }
  .contact__details,.contact_maps,.contact_form{
  margin-top:0!important;
  }
}
.contact__section {
  overflow: auto;
  min-height: 500px;
  background-color: #f4f9fc;
  width: 100%;
  .contact__elements{
    padding:15px;
  }
  .contact_form {
    width: 300px;
    margin-top: 30px;
    margin-bottom: 30px;
    overflow: auto;
    .contact__input {
      margin-top: 10px;
      input,
      textarea {
        border-radius: 10px;
        height: 35px;
        padding: 5px;
        padding-left: 15px;
        width: 280px;
        box-shadow: 0 0 15px #edf2f5;
        border: 0px solid;
        font-family: "Raleway", sans-serif !important;
        &:hover {
          border: 0px solid;
        }
      }

      textarea {
        height: 150px;
        font-family: "Raleway", sans-serif !important;
        font-size: 13.3px;
        font-weight: inherit;
        resize: none;
      }
    }
    .name__input {
      margin-top: 0;
    }
  }
  .contact_maps {
    background: white;
    width: 300px;
    height: 330px;
    border-radius: 20px;
    box-shadow: 0 0 15px #edf2f5;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .contact__details {
    .contact__icon {
      padding: 20px;
      font-size: 35px;
    }
    .contact_email,
    .contact_phone {
      background: white;
      width: 300px;
      // height: 150px;
      padding:10px;
      margin: 30px;
      //
      margin-left: auto;
      margin-right: auto;

      border-radius: 20px;
      box-shadow: 0 0 15px #edf2f5;
      .contact_instrument {
        margin-top: 10px;
        font-weight: 700;
        color: #be8e0b;
      }
    }
    .contact_email {
    }
  }
}
</style>
