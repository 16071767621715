<template>
  <section class="hero">
    <div class="hero__pictures">
      <div class="picture__warpper">
        <div
          class="picture picture1"
          :style="{
            backgroundImage:
              'url(' + require('@/assets/images/hero/hand-landing.jpg') + ')',
          }"
        ></div>
        <div
          class="picture picture2"
          :style="{
            backgroundImage:
              'url(' + require('@/assets/images/hero/hand2-landing.jpg') + ')',
          }"
        ></div>
      </div>
      <div class="border__mobile"></div>
    </div>
    <div class="border__desktop"></div>
    <div class="hero__description">
      <div class="hero__title">
        <span class="yellow ">U</span>niversal
        <span class="yellow ">C</span>ommunity
        <span class="yellow ">S</span>ervices
      </div>
      <div class="hero__content">
        If we are not to teach the
        <b>future leaders of today, <span class="yellow">Who Will?</span></b>
        <br /><br />

        <span class="yellow bold">UCS</span> is a Non-profit Organisation that
        strives to<b> empower youths. Joining them in their journey </b>
        in finding and developing their
        <span class="yellow bold">PASSION</span>
      </div>
      <div class="hero__button__container">
        <main-button class="hero__button" title="Empower Now" link="/facebook" />
      </div>
    </div>
  </section>
</template>
<script>
import MainButton from "@/components/MainButton.vue";

export default {
  components: {
    MainButton,
  },
};
</script>
<style lang="scss" scoped>
.hero__button__container {
  padding-top: 30px;
}
.hero__button {
  margin: auto;
}
.bold {
  font-weight: 700;
}
.hero__content {
  // background-color:black;
}
.hero__title {
  font-weight: 700;
  font-size: 40px;
  padding-top: 35px;
  padding-bottom: 20px;
}
.hero__description {
  margin-left: 10%;
  margin-right: 10%;
  padding-bottom: 30px;
}
.yellow {
  color: #be8e0b;
}
.hero {
  background-color: white;
  min-height: 80vh;
  padding-top: 80px;
}
.hero__pictures {
  display: flex;
  justify-content: center;
  position: relative;
  .picture__warpper {
    display: flex;
    justify-content: space-between;
  }
  .picture__warpper .picture {
    background-color: black;
    width: 120px;
    height: 180px;
    box-shadow: 10px 10px 5px #d8d8d8;
    border-radius: 30px 30px 30px 30px;
    margin: 10px;
  }
  .picture__warpper .picture1 {
    background: center;
    background-size: cover;
  }
  .picture__warpper .picture2 {
    margin-top: 30%;
    margin-bottom: 15%;
    background: center;
    background-size: cover;
  }
  .border__mobile {
    position: absolute;
    height: 3px;
    width: 200px;
    bottom: -4px;
    background: #be8e0b;
  }
  @media only screen and (min-width: 1100px) {
    .border__mobile {
      display: none;
    }
  }
}
.border__desktop{
    height: 200px;
    width: 3px;
    bottom: -4px;
    background: #be8e0b;
    margin-left:5%;
    display:none;
}
@media only screen and (min-width: 1100px) {
  .hero {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .picture__warpper{
    margin-left:200px;
  }
  .hero__description{
    text-align:left;
    width:50%;
    margin-left:5%
  }
  .hero__button{
    margin:0;
  }
  .picture{
    width: 150px!important;
    height: 225px!important;
  }
  .border__desktop{
    display:block;
  }
}
</style>
